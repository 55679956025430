.home-page-ecowrap-logo img {
    width: 90%;
    max-width: 300px;
    object-fit: contain;
}

.suggestions-list {
    width: 100%;
    /* Ensure the list takes full width of the container */
    max-height: 500px;
    /* Set max height for scrollable list */
    overflow-y: auto;
    /* Allow scrolling if list is long */
    padding: 0;
    /* Remove default padding for clean look */
    margin: 0;
    /* Remove default margin */
    border-radius: 5px;
    /* Optional: make list corners rounded */
}

.striped-item {
    padding: 10px;
    /* Add some padding to list items */
    cursor: pointer;
    /* Make it clear that the items are clickable */
    transition: background-color 0.2s ease;
    /* Smooth transition for hover effect */
}

.striped-item:hover {
    background-color: #e6f7ff;
    /* Light blue on hover for better feedback */
}

.even {
    background-color: #f9f9f9;
    /* Light grey for even items */
}

.odd {
    background-color: #ffffff;
    /* White for odd items */
}